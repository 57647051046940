<template>
	<div>
		<div class="header filter">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="$emit('goBack')"
							class="arrow_white">
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{  program.title }}</h2>
						<span class="page_subtit">{{  item_cartel.title }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div class="section_wrap pt-70">
			<!-- 탭선택 -->
			<div class="member_tab">
				<div class="container">
					<div class="row">
						<div class="list_tab pt-20">
							<ul>
								<!-- TODO : 활성화 탭 on 클래스 -->
								<li
									:class="{ on: type == 'stat'}"
								><a
									@click="type = 'stat'"
									class="btn_l btn_fill_lightgray"
								>{{  $language.common.statistics }}</a></li>
								<li
									:class="{ on: type == 'member'}"
								><a
									@click="type = 'member'"
									class="btn_l btn_fill_lightgray"
								>{{  $language.common.excellent_member }}</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!-- //탭선택 -->
			<!-- 조회 -->
			<div class="ct_detail_wrap">
				<!-- 스케줄 검색 -->
				<div class="ct_header_wrap">
					<div class="container">
						<div class="row">
							<!-- 일간/주간/월간 -->
							<div class="ct_tab_component_btn">
								<ul>
									<!-- TODO : 탭 선택시 on 추가 -->
									<li
										:class="{on: item_search.stat_division == 'daily'}"
									><a
										@click="setDivision('daily')"
									><span class="t_line"></span>{{  $language.common.daily }}</a></li>
									<li
										:class="{on: item_search.stat_division == 'weekly'}"
									><a
										@click="setDivision('weekly')"
									><span class="t_line"></span>{{  $language.common.weekly }}</a></li>
									<li
										:class="{on: item_search.stat_division == 'monthly'}"
									><a
										@click="setDivision('monthly')"
									><span class="t_line"></span>{{  $language.common.monthly }}</a></li>
								</ul>
							</div>
							<!-- //일간/주간/월간 -->
							<!-- 조회영역 -->
							<div class="tab_info_wrap">
								<!-- 날짜 -->
								<div class="ct_info_section">

									<div class="input-date-box">
										<button
											@click="prev"
											class="btn_arrow"
										><i class="icon-arrow-left"></i></button>
										<!-- TODO : 프론트작업요청 -->

										<span @click="is_picker = !is_picker" style="font-size: 14px;">
											{{ text_picker }}
										</span>

										<div
											v-if="is_picker"
											style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; display: flex; flex-direction: column; align-items: center; justify-content: center"
										>
											<div
												style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: black; opacity: 0.2"
											></div>
											<div
												style="border: 1px solid #bbb"
											>
											<v-date-picker
												v-if="item_search.stat_division == 'daily'"
												v-model="item_search.date"
												no-title
												scrollable
												locale="ko-KR"
												@change="checkDate"
											></v-date-picker>

											<v-date-picker
												v-if="item_search.stat_division == 'weekly'"
												v-model="item_search.w_date"
												no-title
												scrollable
												range
												locale="ko-KR"
												@change="checkDate"
											></v-date-picker>

											<v-date-picker
												v-if="item_search.stat_division == 'monthly'"
												v-model="item_search.monthly"
												no-title
												scrollable
												type="month"
												locale="ko-KR"
												@change="checkDate"
											></v-date-picker>

												<button
													@click="getData(); is_picker = false"
													style="width: 100%; padding: 10px; background-color: #3a44c0; color: white; font-size: 14px"

												>{{  $language.common.ok }}</button>
											</div>

										</div>

										<button
											@click="next"
											class="btn_arrow"
										><i class="icon-arrow-right"></i></button>
									</div>
								</div>
								<!-- //날짜 -->
								<!--테이블 -->
								<div
									v-if="type == 'stat'"
									class="ct_table_section pt-20"
								>
									<table>
										<thead>
										<tr class="t_head">
											<th class="t_tit">{{  $language.common.visit_count }}</th>
											<th class="t_tit">{{  $language.common.join_count }}</th>
											<th class="t_tit">{{  $language.common.new_article_count }}</th>
										</tr>
										</thead>
										<tbody>
										<tr class="t_body">
											<td class="t_conut">{{ item_stats.visit_count }}</td>
											<td class="t_conut">{{ item_stats.join_count }}</td>
											<td class="t_conut">{{ item_stats.new_count }}</td>
										</tr>
										</tbody>
									</table>
								</div>
								<!--//테이블 -->
							</div>
							<!-- //조회영역 -->
						</div>
					</div>
				</div>
				<!-- //스케줄 검색 -->
				<!-- 그래프탭 영역 -->
				<div
					v-if="type == 'stat'"
					class="ct_stats_detail_wrap subindex_item"
				>
					<div class="container">
						<div class="row">
							<div class="ct_manu_section">
								<!-- 방문자수/가입자수/새글수 -->
								<div class="ct_tab_component_line">
									<ul>
										<!-- TODO : 탭 선택시 on 추가 -->
										<li
											:class="{on: item_search.stat_type == 'visit'}"
										><a
											@click="setType('visit')"
										><span class="t_line"></span>{{  $language.common.visit_count }}</a></li>
										<li
											:class="{on: item_search.stat_type == 'join'}"
										><a
											@click="setType('join')"
										><span class="t_line"></span>{{  $language.common.join_count }}</a></li>
										<li
											:class="{on: item_search.stat_type == 'new'}"
										><a
											@click="setType('new')"
										><span class="t_line"></span>{{  $language.common.new_article_count }}</a></li>
									</ul>
								</div>

								<!-- //방문자수/가입자수/새글수 -->
							</div>
							<div class="ct_basic_section">
								<span class="ch_date">{{  item_search.s_date }}</span>
								<span class="ch_value">{{  item_stats[item_search.stat_type + '_count'] }}</span>
							</div>
							<div class="ct_chart_section">
								<LineChartGenerator
									:chart-options="chartOptions"
									:chart-data="chartData"
								/>
							</div>
						</div>
					</div>
				</div>

				<!-- //그래프탭 영역 -->
				<div
					v-if="type == 'member'"
					class="ct_list_wrap subindex_item"
				>
					<div class="container">
						<div class="row">
							<!-- 우수멤버 리스트 -->
							<div
								v-if="items_stat_member.length > 0"
								class="thumbnail_list ct_best"
							>
								<!-- 베스트3 -->
								<div
									v-for="(item, index) in items_stat_member"
									:key="'item_' + index"
									class="ct_bestmember_list"
								>
									<div
										class="circle_thumbnail_item"
									>
										<div
											class="clear " :class="{ top_3: index < 3}"
										>
											<div class="picture">
												<img
													v-if="item.img_src"
													:src="$request.upload_url(item.img_src)"
												/>
												<img
													v-lese
													:src="require('@/assets/image/@noimage2.png')"
												/>
											</div>
											<span class=" level" :class="{best_level: index == 0}"><em class="hide">{{ item.user_rank }}</em></span>
											<div class="text_area">
												<strong>{{ item.user_nick }}</strong>
												<p class="textzone">{{  item.user_level }}</p>
											</div>
										</div>
										<div class="ct_detail_desc">
											<div>
												<div class="info"><span class="tit">{{  $language.common.visit }}</span><em>{{  item.visit_count }}</em></div>
												<div class="info pt-8"><span class="tit">{{  $language.notice.register_article }}</span><em>{{  item.new_count }}</em></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								v-else
							>
								<div class="list_none" style="overflow: hidden; margin-top: 50%">
									<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
									<span>{{  $language.common.no_excellent_member }}</span>
								</div>
							</div>
							<!-- //우수멤버 리스트 -->
						</div>
					</div>
				</div>
			</div>
			<!-- //조회 -->
		</div>
	</div>
</template>

<script>

	import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

	import {
		Chart as ChartJS,
		Title,
		Tooltip,
		Legend,
		LineElement,
		LinearScale,
		CategoryScale,
		PointElement
	} from 'chart.js'

	ChartJS.register(
		Title,
		Tooltip,
		Legend,
		LineElement,
		LinearScale,
		CategoryScale,
		PointElement
	)

	export default {
		name: 'mafia0911'
		, props: ['user']
		, components: { LineChartGenerator }
		, data: function(){
			return {
				program: {
					name: this.$language.cartel.analyze_cartel
					, title: this.$language.cartel.analyze_cartel
					, not_header: true
					, not_footer: true
					, type: 'cartel_sub'
				}
				, type: 'stat'
				, item_cartel: {}
				, item_stats: {
					visit_count: 1
					, join_count: 2
					, new_count: 3
				}
				, items_stat_member: [

				]
				, items_stat: []
				, is_picker: false
				, item_search: this.$storage.init({
					stat_division: 'daily'
					, stat_type: 'visit'
					, daily: this.$date.getToday('-')
					, monthly: this.$date.init('', '-').year + '-' + this.$date.init().month
					, weekly: this.$date.init('', '-').year + '-' + this.$date.init('', '-').weeks
					, s_date: this.$date.getWeeklyDate('', '-').start
					, e_date: this.$date.getWeeklyDate('', '-').end
					, w_date: []

				})
				, chartData: {
					labels: []
					, datasets:[
						{
							label: 'Data One'
							, backgroundColor: '#f87979'
							, data: []
							, showLabel: false
							, pointStyle: 'circle'
							, pointColor: 'red'
							, options: {
								legend: {
									display: false //This will do the task
								}
							}
						}
					]
				},
				chartOptions: {
					responsive: true,
					maintainAspectRatio: true,
					legend: {
						display: false
					}
				}
			}
		}
		, computed: {
			text_picker: function(){
				let t = this.item_search[this.item_search.stat_division]
				if(this.item_search.stat_division == 'weekly'){
					t = this.item_search.s_date + ' ~ ' + this.item_search.e_date
				}
				return t
			}
		}
		, methods: {
			getData: async function () {
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'get'
						, url: this.$api_url.api_path.get_cartel_stat
						, data: {
							type: 'cartel_stat'
							, key: 'type'
							, val: this.item_search.stat_division
						}
					})

					if (result.success) {
						this.items_stat = result.data
						console.log(result.data.data)
						this.chartData.datasets[0].data = result.data.data
						await this.setLabel()

						await this.getMemberRank()
						this.$storage.setQuery(this.item_search)
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
					this.$bus.$emit('notify', {type: 'error', message: e})
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, getMemberRank: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'get'
						, url: this.$api_url.api_path.get_member_rank
						, data: {
							type: 'member_rank'
							, key: 'type'
							, val: this.item_search.stat_division
						}
					})

					if (result.success) {
						this.items_stat_member = result.data.data
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
					this.$bus.$emit('notify', {type: 'error', message: e})
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, prev: function () {
				switch (this.item_search.stat_division) {
					case 'daily':
						this.item_search.daily = this.$date.getPrevDay(this.item_search.daily, '-')
						break
					case 'weekly':
						this.item_search.s_date = this.$date.getPrevWeelkyDate(this.item_search.s_date, '-').start
						this.item_search.e_date = this.$date.getWeeklyDate(this.item_search.s_date, '-').end
						this.item_search.weekly = this.$date.init(this.item_search.s_date, '-').fullWeeks
						break
					case 'monthly':
						this.item_search.monthly = this.$date.getPrevMonth(this.item_search.monthly + '-01', '', '-').fullMonth
						break
				}

				this.getData()
			}
			, next: function () {
				let is_do = false
				switch (this.item_search.stat_division) {
					case 'daily':
						if(this.$date.getToday('-') >= this.$date.getNextDay(this.item_search.daily, '-')){
							is_do = true
							this.item_search.daily = this.$date.getNextDay(this.item_search.daily, '-')
						}
						break
					case 'weekly':
						if(this.$date.getToday('-') > this.item_search.e_date) {
							this.item_search.s_date = this.$date.getNextWeelkyDate(this.item_search.e_date, '-').start
							this.item_search.e_date = this.$date.getWeeklyDate(this.item_search.s_date, '-').end
							this.item_search.weekly = this.$date.init(this.item_search.s_date, '-').fullWeeks
							is_do = true
						}

						break
					case 'monthly':
						if(this.$date.getToday('-').slice(0, 7) > this.item_search.monthly) {
							this.item_search.monthly = this.$date.getNextMonth(this.item_search.monthly + '-01', '', '-').fullMonth
							is_do = true
						}
						break
				}

				if(is_do){
					this.getData()
				}
			}
			, setDivision: function (type) {
				this.item_search.stat_division = type
				this.getData()
			}
			, setType: function (type) {
				this.item_search.stat_type = type
				this.getData()
			}
			, setWdate: function(e){
				this.item_search.s_date = this.$date.getWeeklyDate(this.item_search.w_date[0], '-').start
				this.item_search.e_date = this.$date.getWeeklyDate(this.item_search.w_date[0], '-').end
				this.item_search.w_date = [this.item_search.s_date, this.item_search.e_date]
				console.log('setWdate', e)
			}
			, setLabel: function(){
				let data = []
				let start = ''
				switch (this.item_search.stat_division){
					case 'daily':
						start = this.$date.getNextDay(this.item_search.daily, '-')
						for(let i = 0; i < 7; i++){
							console.log(i, start)
							data.unshift(this.$date.getPrevDay(start, '-').slice(-2) + '일')
							start = this.$date.getPrevDay(start, '-')
							console.log('start', start)
						}
						break
					case 'weekly':
						start = this.$date.getNextWeelkyDate(this.item_search.s_date, '-').start
						for(let i = 0; i < 7; i++){
							console.log('weekly ' + i, start)
							data.unshift(this.$date.getPrevWeelkyDate(start, '-').fullWeeks + '주')
							start = this.$date.getPrevWeelkyDate(start, '-').start
						}
						break
					case 'monthly':
						start = this.$date.getNextMonth(this.item_search.monthly, 1, '-').fullMonth
						for(let i = 0; i < 7; i++){
							console.log(i, start)
							data.unshift(this.$date.getPrevMonth(start + '-01', '', '-').fullMonth)
							start = this.$date.getPrevMonth(start + '-01', '', '-').fullMonth
							console.log('start', start)
						}
						break
				}
				console.log('label', data)
				this.chartData.labels = data
			}
			, checkDate: function(){
				switch (this.item_search.stat_division){
					case 'daily':
						if(this.$date.getToday('-') > this.item_search.date){
							this.item_search.daily = this.item_search.date
						}
						break
					case 'weekly':
						break
					case 'monthly':
						if(this.item_search.monthly > this.$date.getToday('-').slice(0, 7)) {
							this.item_search.monthly = this.$date.getToday('-').slice(0, 7)
						}
						break
				}
			}
			, getCartel: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'get'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							type: 'cartel'
							, key: 'id'
							, val: this.$route.params.idx
						}
					})

					if(result.success){
						this.item_cartel = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
			this.getCartel()
		}
		, watch: {
			'item_search.w_date': {
				handler: function(){
					if(this.item_search.w_date[0] == this.item_search.s_date){
						return false
					}
					let s;
					let e;
					if(this.item_search.w_date[0] > this.$date.getToday('-')){
						s = this.$date.getWeeklyDate(this.$date.getToday('-'), '-').start
						e =this.$date.getWeeklyDate(this.$date.getToday('-'), '-').end
					}else{
						s = this.$date.getWeeklyDate(this.item_search.w_date[0], '-').start
						e =this.$date.getWeeklyDate(this.item_search.w_date[0], '-').end
					}
					console.log('item_search.w_date')
					this.item_search.w_date = [s, e]

					this.item_search.s_date = s
					this.item_search.e_date = e
					this.item_search.weekly = this.$date.init(s, '-').fullWeeks
				}
			}
			, item_search: {
				deep: true
				, handler: function (call){
					console.log('call', call)
				}
			}
		}
	}
</script>

<style>
	.v-date-picker-table .v-btn.v-btn--active { color: black !important;}
	.v-date-picker-header__value button { font-size: 18px !important;}
</style>